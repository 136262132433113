<!--
 * @Description: 
 * @Author: YJJ
 * @Date: 2023-12-08 17:14:52
 * @LastEditTime: 2023-12-08 17:40:28
-->
<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import wx from 'weixin-js-sdk'; 

export default {
  name: "App",
  computed: {
    
  },
  methods: {
      // vue调用微信的自定义分享
    getShareInfo() {
       this.save = {
          url: 'https://www.zgshiye.cn/' // 只需要传当前页面地址
        };
        this.$post("/api/service/sign", this.save).then((res) => {
          const config = res.data;
          if(!config) return ;
          wx.config({
            debug: false, 
            appId: "wx99071c1d944ee6aa",  // appID 公众号的唯一标识
            timestamp: config.timestamp, // 生成签名的时间戳
            nonceStr: config.nonceStr, //  生成签名的随机串
            signature: config.signature, // 生成的签名
            jsApiList: [
              'updateAppMessageShareData', // 分享到朋友
              'updateTimelineShareData', // 分享到朋友圈
              'onMenuShareAppMessage',
            ]
          });
          wx.ready(() => {
          var shareData = {
            title: '中观企服',
            desc: ' 专业 方便 快捷 ',
            link: this.save.url, // 分享后的地址
            imgUrl:"https://www.zgshiye.cn/zgsy-logo.jpg",
            success: function() {
                // alert("分享成功");
            }
          };
          //点击要去分享
          wx.updateAppMessageShareData(shareData);
          wx.updateTimelineShareData(shareData);
          wx.onMenuShareAppMessage(shareData);  
        });
        wx.error(function(res){
          console.log(res);
        }); 

      });

    }
  },
  created() {
    this.getShareInfo();
  },
};
</script>


<style lang="scss">
* {
  margin: 0;
  padding: 0;
}
</style>
